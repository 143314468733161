import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from "luxon";

@Pipe({
  name: 'dutchDate'
})
export class DutchDatePipe implements PipeTransform {

  transform(value: Date | DateTime | string | undefined | null, format?: string | null, timezone?: string, locale?: string): string | null {
    if (value === undefined || value === null) {
      return null;
    }

    if (typeof value === 'string') {
      return this.datetimeFormat(DateTime.fromISO(value), format, timezone, locale);
    }

    if (value instanceof Date) {
      return this.datetimeFormat(DateTime.fromJSDate(value), format, timezone, locale);
    }

    return this.datetimeFormat(value, format, timezone, locale);
  }

  datetimeFormat(value: DateTime, format?: string | null, timezone?: string, locale?: string): string | null {
    let v = value;

    if (timezone) {
      v = v.setZone(timezone);
    }

    if (format === undefined || format === null) {
      return v.toLocaleString({weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'}, {locale: locale});
    }

    return v.toFormat(format, {locale: locale});
  }

}
