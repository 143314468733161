import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {filter, map, startWith} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {ResponsiveService} from "./services/responsive.service";
import {LoggingService} from "./services/logging.service";

export type SupportedLanguage = 'nl-NL' | 'en-US' | 'de-DE';
export const languageOptionMap: {[key in SupportedLanguage]: {rfc5646: string, nativeName: string, image: string}} = {
  "nl-NL": {rfc5646: 'nl-NL', nativeName: 'Nederlands', image: '/assets/images/flags/nl.svg'},
  "en-US": {rfc5646: 'en-US', nativeName: 'English', image: '/assets/images/flags/gb.svg'},
  "de-DE": {rfc5646: 'de-DE', nativeName: 'Deutsch', image: '/assets/images/flags/de.svg'}
};
export const supportedLanguages = Object.keys(languageOptionMap) as SupportedLanguage[];
export const defaultLanguage = languageOptionMap["nl-NL"];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  languageOptionMap = languageOptionMap;
  languageOptionKeys = supportedLanguages;

  activeLanguage: string | null = null;
  onProcessingPage = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public responsiveService: ResponsiveService,
    private loggingService: LoggingService
  ) {
    loggingService.initialize();
    iconRegistry.registerFontClassAlias('material-icons-two-tone', 'material-icons-two-tone');
  }

  ngOnInit(): void {
    this.addCustomIcons();
    this.initializeTranslations();
    this.observeOnProcessingPage();
  }

  private observeOnProcessingPage() {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(event => {
        const url = new URL(event.url, window.location.origin);
        this.onProcessingPage = url.pathname === '/processing';
      });
  }

  private initializeTranslations() {
    this.translate.defaultLang = defaultLanguage.rfc5646;

    const browserLang = this.translate.getBrowserCultureLang();
    if (browserLang !== undefined && browserLang in languageOptionMap) {
      this.translate.use(browserLang);
    } else {
      this.translate.use(defaultLanguage.rfc5646);
    }

    this.activatedRoute.queryParams
      .pipe(
        filter(params => 'lang' in params),
        map(params => params['lang'] as string)
      ).subscribe(paramsLang => this.translate.use(paramsLang));

    this.translate.onLangChange
      .asObservable()
      .pipe(
        map(changeEvent => changeEvent.lang),
        startWith(this.translate.defaultLang)
      )
      .subscribe(activeLanguage => {
        this.activeLanguage = activeLanguage;
        this.cdr.detectChanges();
      });
  }

  get availableLanguages(): SupportedLanguage[] {
    return this.languageOptionKeys.filter(key => key !== this.activeLanguage);
  }

  selectLanguage(language: SupportedLanguage) {
    this.translate.use(language);
  }

  private addCustomIcons() {
    // Add language flags
    this.languageOptionKeys
      .map(languageOptionKey => languageOptionMap[languageOptionKey])
      .forEach(languageOption => this.iconRegistry.addSvgIcon(languageOption.rfc5646, this.domSanitizer.bypassSecurityTrustResourceUrl(languageOption.image)));
  }
}
