import {Component, inject, input, Signal} from '@angular/core';
import {MatStepper, MatStepperNext} from "@angular/material/stepper";
import {ExtraService} from "../extra.service";
import {toSignal} from "@angular/core/rxjs-interop";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {combineLatest, filter, map, Observable, of, startWith, switchMap} from "rxjs";
import {isLoadingState, LoadingState, withoutLoadingState} from "../../../../helpers/CustomRxjsOperators";
import {FindOrderResponse} from "../../../../api/generated";
import {PipesModule} from "../../../../pipes/pipes.module";
import {AsyncPipe, NgClass} from "@angular/common";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatCard, MatCardContent} from "@angular/material/card";
import {ResponsiveService} from "../../../../services/responsive.service";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {notNullOrUndefined} from "../../../../helpers/UtilityFunctions";
import {DateTime} from "luxon";
import {ComponentsModule} from "../../../../components/components.module";
import {MatList, MatListItem, MatListModule} from "@angular/material/list";
import {MatLine} from "@angular/material/core";
import {environment} from "../../../../../environments/environment";
import {SupportedLanguage} from "../../../../app.component";

@Component({
  selector: 'app-extra-step-1',
  standalone: true,
  imports: [
    TranslateModule,
    PipesModule,
    AsyncPipe,
    MatProgressSpinner,
    MatCard,
    MatCardContent,
    NgClass,
    MatButton,
    MatIcon,
    MatStepperNext,
    ComponentsModule,
    MatListModule
  ],
  templateUrl: './extra-step-1.component.html',
  styleUrl: './extra-step-1.component.scss'
})
export class ExtraStep1Component {
  stepper = input.required<MatStepper>();
  isActive = input.required<boolean>();

  private extraService = inject(ExtraService);
  translateService = inject(TranslateService);
  responsiveService = inject(ResponsiveService);

  private fancyDateFormats: {[key in SupportedLanguage]: string} = {
    "nl-NL": 'EEEE d MMMM yyyy',
    "en-US": 'EEEE, d MMMM yyyy',
    "de-DE": "EEEE, 'den' d. MMMM yyyy"
  };

  dateFormat$ = this.translateService.onLangChange
    .pipe(startWith(this.translateService.currentLang), map(() => this.fancyDateFormats[this.translateService.currentLang as SupportedLanguage]));

  imageBaseUrl = environment.articleImageBaseUrl;

  order$ = this.extraService.order$;
  articles$ = this.extraService.originalLocalizedArticles$.pipe(filter(withoutLoadingState));

  loading$ = combineLatest([
    this.order$.pipe(filter(isLoadingState)),
    this.extraService.originalLocalizedArticles$.pipe(filter(isLoadingState))
  ])
    .pipe(
      map(([orderLoading, articlesLoading]) => ({loading: orderLoading.loading || articlesLoading.loading})),
      startWith({loading: true})
    );

  order: Signal<FindOrderResponse | null | undefined> = toSignal(this.order$.pipe(filter(withoutLoadingState)));
  loading: Signal<LoadingState | undefined> = toSignal(this.loading$);

  private orderTitle$ = this.order$
    .pipe(
      filter(withoutLoadingState),
      filter(notNullOrUndefined),
      switchMap(order => this.translateService.onLangChange.pipe(startWith(null), map(() => order))),
      switchMap(order => this.buildExpositionName(order))
    );

  orderTitle = toSignal(this.orderTitle$);

  constructor() {
  }

  buildExpositionName(order: FindOrderResponse): Observable<string | null> {
    const codeParts = order.expositionCode.split(' ');
    const type = codeParts[0];

    switch (type) {
      case 'SL':
        return this.buildSloopName(order);
      case 'RV':
        return this.buildTourName(order);
      case 'AR':
        return this.buildGroupName(order);
      case 'GV':
        return this.buildGroupName(order);
    }

    return of(null);
  }

  private buildSloopName(order: FindOrderResponse): Observable<string> {
    const hoursAvailableRegex = /^(\d)+U$/;
    const capacityRegex = /^(\d+)P(P)?\*$/;

    const codeParts = order.expositionCode.split(' ');

    const hoursAvailableCode = codeParts.filter(part => hoursAvailableRegex.test(part));
    const capacityCode = codeParts.filter(part => capacityRegex.test(part));

    const hours = hoursAvailableCode.length > 0 ? hoursAvailableCode[0]?.match(hoursAvailableRegex)?.[1] : null;
    const capacity = capacityCode.length > 0 ? capacityCode[0]?.match(capacityRegex)?.[1] : null;

    return this.translateService.get('PAGES.PRODUCTS.EXTRA.EXPOSITION_TITLES.SLOOP', {
      start: DateTime.fromISO(order.startDate).toFormat('HH:mm'),
      end: DateTime.fromISO(order.endDate).toFormat('HH:mm')
    });
  }

  private buildTourName(order: FindOrderResponse): Observable<string> {
    const hours = DateTime.fromISO(order.endDate).diff(DateTime.fromISO(order.startDate), 'hours').hours;

    return this.translateService.get('PAGES.PRODUCTS.EXTRA.EXPOSITION_TITLES.TOUR', {hours});
  }

  private buildGroupName(order: FindOrderResponse): Observable<string> {
    return this.translateService.get('PAGES.PRODUCTS.EXTRA.EXPOSITION_TITLES.GROUP');
  }
}
